<template>

  <v-app-bar app class="headBar" flat>

    <v-breadcrumbs :items="this.$store.state.breadcrumbs"/>

    <v-spacer/>

    <v-toolbar-title>{{ plantName }}</v-toolbar-title>

    <v-menu bottom left offset-y>

      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-5" fab small color="secondary" v-bind="attrs" v-on="on" >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list class="">
        <v-list-item v-for="(item, i) in userMenuItems" :key="i" @click="userMenuClick(item.click)" :to="item.route">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-bind="attrs" v-on="on">
                <v-icon left>{{ item.icon }}</v-icon>{{ item.title }}
              </v-list-item-title>
            </template>
            <span>{{ item.tooltip }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>

    </v-menu>
    
    &nbsp;&nbsp;

    <Plants :show="plantsDialog" :on-close="()=> plantsDialog = false" />
  </v-app-bar>

</template>

<style scoped>
  .theme--light.v-app-bar.v-toolbar.v-sheet {
    background-color: #f1f0ff;
    border-bottom: 1px solid silver;
  }
</style>

<script>
import Plants from '@/components/Plants';
import { mapState } from 'vuex'

export default {
  components: {
    Plants
  },
  computed: {
    ...mapState(['plantName']),
  },
  props: {
    user: undefined
  },
  data: function() {    
    return { 
      userMenuItems: [
        { title: this.user.display_name,     click:"user",     icon:"mdi-account-outline", tooltip: this.user.display_name,   route: '/user', },
        { title: this.$t("config"),   click:"config",   icon:"mdi-cog-outline",     tooltip: this.$t("config_tooltip"), route: '/config', },
        //{ title: this.$t("help"),     click:"help",     icon:"mdi-help",            tooltip: this.$t("help_tooltip"),   route: '/help', },
        { title: this.$t("logout"),   click:"logout",   icon:"mdi-logout",          tooltip: this.$t("logout_tooltip") },
      ],
      plantsDialog: false,
    }
  },
  mounted(){
    const {plantIds, role} = this.user;
    if(plantIds && plantIds.length > 1 || role == 'SUPERADMIN'){
      this.userMenuItems.unshift({ title: this.$t("plants"), click:"plants", icon:"mdi-office-building-cog", tooltip: this.$t("plants"),  });
    }
  },
  methods: {       
    userMenuClick(click){
      if(click =='logout'){
        this.$store.dispatch('_logout');
      }
      if(click =='plants'){
        this.plantsDialog = true;
      }
    },
  }  
}
</script>
