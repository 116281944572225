<template>
  <v-row justify="center">
    <v-dialog :value="show" overlay-opacity="0.4" overlay-color="#ffffff" persistent>
      <v-card flat>
        <v-overlay :absolute="true" :value="loading">
          <v-row align="center" justify="center">
            <v-col align="center">
              <v-progress-circular indeterminate color="info" :size="350">
              </v-progress-circular>
            </v-col>
          </v-row>
        </v-overlay>
        <v-card-title class="text-h5 blue darken-4 white--text">
          <v-toolbar-title>{{
            `${(currentItem && currentItem.sourceId) || isView ? "View" : "New"} Alert`
            }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card flat>
                <v-card-text>
                  <p class="text-h5 text--primary">
                    <v-icon left color="grey">mdi-cog</v-icon>
                    {{ $t("Alert info") }}
                  </p>

                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-radio-group :disabled="!!currentItem.sourceId || isView" v-model="currentItem.objectType" row
                      label="Object Type" @change="changeObjectType">
                      <v-radio label="Structure" value="STRUCTURE"> </v-radio>
                      <v-radio label="Element" value="ELEMENT"> </v-radio>
                    </v-radio-group>

                    <v-select :disabled="!!currentItem.sourceId || isView" v-if="currentItem.objectType === 'STRUCTURE'"
                      v-model="object" :rules="[rules.required]" :items="structures" label="Object" item-text="label"
                      item-value="structureId" return-object @change="changeObject">
                    </v-select>
                    <v-select :disabled="!!currentItem.sourceId || isView" v-if="currentItem.objectType === 'ELEMENT'"
                      v-model="object" :rules="[rules.required]" :items="elements" label="Object" item-text="label"
                      item-value="elementId" return-object @change="changeObject">
                    </v-select>

                    <v-text-field :disabled="!!currentItem.sourceId || isView" label="Name" v-model="currentItem.name"
                      required :rules="[rules.required]"></v-text-field>

                    <v-textarea :disabled="!!currentItem.sourceId || isView" label="Description"
                      v-model="currentItem.description"></v-textarea>

                    <v-select :disabled="!!currentItem.sourceId || isView" v-model="selectedLevel" :items="levels"
                      label="Level" item-text="label" item-value="id" return-object :rules="[rules.required]">
                    </v-select>
                    <v-select :disabled="!!currentItem.sourceId || isView" v-model="selectedFamily" :items="families"
                      label="Family" item-text="algorithmFamily" item-value="algorithmFamilyId" @change="changeFamily"
                      return-object :rules="[rules.required]">
                    </v-select>
                    <v-select :disabled="!selectedFamily || !!currentItem.sourceId || isView"
                      v-model="selectedAlgorithm" :items="algorithms" label="Algorithm" item-text="algorithm"
                      item-value="algorithmId" return-object @change="changeAlgorithm" :rules="[rules.required]">
                    </v-select>
                    <v-select :disabled="!selectedAlgorithm || !!currentItem.sourceId || isView"
                      v-model="selectedProperty" :items="properties" label="Property" item-text="label" item-value="id"
                      return-object :rules="[rules.required]">
                    </v-select>
                    <v-select :disabled="!!currentItem.sourceId || isView" v-model="selectedOperator" :items="operators"
                      label="Operator" item-text="operatorLabel" item-value="opeatorId" return-object
                      :rules="[rules.required]">
                    </v-select>
                    <v-text-field :disabled="!selectedOperator || isView" label="Value1" type="number"
                      v-model.number="currentItem.value1" required></v-text-field>
                    <v-text-field :disabled="!selectedOperator || isView" v-if="value2Visibility" label="Value2"
                      type="number" v-model.number="currentItem.value2" required></v-text-field>
                    <v-switch
                      v-if="selectedOperator?.operatorName !== 'EQ' && selectedOperator?.operatorName !== 'NOTEQ'"
                      label="Limit Inclusive" v-model="limitInclusive"
                      :disabled="!selectedOperator || isView"></v-switch>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col v-if="!currentItem.sourceId" cols="6">
              <v-card flat>
                <v-card-text>
                  <p class="text-h5 text--primary">
                    <v-icon left color="grey">mdi-access-point</v-icon>
                    {{ $t("Points") }}
                  </p>
                  <v-data-table v-model="selectedEntities" :headers="[{ text: 'Entities', value: 'label' }]"
                    :items="entities" item-key="objectId" :show-select="!currentItem.id" :items-per-page="10">
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!currentItem.sourceId && !isView" @click="saveAlert()" color="success">
            <v-icon left>mdi-floppy</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
// TODO: GLI OPERATOR HANNO ID CHE VARIA DA DB A DB
export default {
  name: "AlertEdit",
  props: [
    "show",
    "onSave",
    "onCancel",
    "selectedItem",
    "preconfiguredObjType",
    "entityId",
    "isView"
  ],
  components: {},
  watch: {
    selectedItem: function (newVal) {
      this.object = null;
      this.selectedFamily = null;
      this.selectedAlgorithm = null;
      this.selectedProperty = null;
      this.selectedOperator = null;
      this.selectedLevel = null;
      this.entities = [];
      this.selectedEntities = [];

      this.loadData(newVal);
    },
  },
  computed: {
    rules() {
      return this.$script.formRules;
    },
    value2Visibility() {
      if (!this.selectedOperator) {
        return false
      }
      return this.selectedOperator.operatorName === 'BTW' || this.selectedOperator.operatorName === 'NOTBTW'
    }
  },
  data() {
    return {
      loading: false,
      currentItem: {},
      families: [],
      familiesAll: [],
      familiesStructures: [],
      algorithms: [],
      entities: [],
      object: null,
      selectedFamily: null,
      selectedLevel: null,
      selectedAlgorithm: null,
      selectedProperty: null,
      selectedOperator: null,
      limitInclusive: false,
      value1: null,
      value2: null,
      structures: [],
      elements: [],
      properties: [],
      operators: [],
      levels: [],
      valid: false,
      selectedEntities: [],
    };
  },
  methods: {
    async loadData(alertFromList) {
      console.log("alert:", alertFromList);
      let alert = { ...alertFromList };
      this.loading = true;
      if (alertFromList.id) {
        alert = await this.$store.dispatch("_getAlertDefinitionDetail", { idDefinedAlert: alertFromList.id })
        this.currentItem = alert;
      } else {
        this.detail = {};
        this.currentItem = {
          objectType: "STRUCTURE",
          ...alert,
        };
      }

      this.limitInclusive = this.currentItem.limitInclusive

      if (alert.structureId) {
        this.currentItem.objectType = "STRUCTURE";
      }

      if (alert.elementId) {
        this.currentItem.objectType = "ELEMENT";
      }

      if (this.currentItem.objectType === "STRUCTURE") {
        await this.loadStructure();
      } else {
        await this.loadElements();
      }

      await this.loadFamily();

      await this.loadOperators();

      await this.loadLevels();

      this.loading = false;
    },
    async loadStructure() {
      this.structures = await this.$store.dispatch("_getStructures");
      if (this.currentItem.structureId) {
        this.object = this.structures.find(
          (x) => x.structureId === this.currentItem.structureId
        );
      }
    },
    async loadElements() {
      this.elements = await this.$store.dispatch("_getElements");
      if (this.currentItem.elementId) {
        this.object = this.elements.find(
          (x) => x.elementId === this.currentItem.elementId
        );
      }
    },
    async loadEntities(entityType, algorithmId, objectId) {
      this.entities = [];
      this.loading = true;
      if (!this.currentItem.id && algorithmId && objectId) {
        this.entities = await this.$store.dispatch("getEnsembleEntities", {
          params: {
            algorithmId,
            objectId,
            entityType,
          },
        });
      }

      console.log('currentItem', this.currentItem);

      if (this.currentItem.id) {
        this.entities = this.currentItem.entities
      }

      console.log('entities', this.entities);

      this.loading = false;
    },
    async loadFamily() {
      this.loading = true;
      this.selectedFamily = null;
      this.familiesAll = await this.$store.dispatch("getEnsembleFamilies");
      this.familiesStructures = this.familiesAll.filter(
        (x) => x.algorithmFamily === "STATISTIC"
      );

      if (this.currentItem && this.currentItem.objectType === "STRUCTURE") {
        this.families = this.familiesStructures;
      } else {
        this.families = this.familiesAll;
      }

      if (this.currentItem.algorithmFamilyId) {
        this.selectedFamily = this.families.find(x => x.algorithmFamilyId === this.currentItem.algorithmFamilyId)
      }

      if (this.selectedFamily) {
        this.changeFamily(this.selectedFamily);
      }
      this.loading = false;
    },
    async loadAlgorithm(algorithmFamilyId) {
      this.loading = true;
      this.algorithms = await this.$store.dispatch("getEnsembleAlgorithms", {
        params: {
          algorithmFamilyId,
        },
      });

      if (this.currentItem.algorithmId) {
        this.selectedAlgorithm = this.algorithms.find(x => x.algorithmId === this.currentItem.algorithmId)
      }

      this.loading = false;
      if (this.selectedAlgorithm) {
        this.changeAlgorithm(this.selectedAlgorithm);
      }
    },
    async loadOperators() {
      this.loading = true;
      this.operators = await this.$store.dispatch("_getAlertsOperators");
      this.loading = false;

      if (this.currentItem.alertOperatorId) {
        this.selectedOperator = this.operators.find(x => x.operatorId === this.currentItem.alertOperatorId)
      }
    },
    async loadLevels() {
      this.loading = true;
      this.levels = await this.$store.dispatch("_getAlertsLevels");
      this.loading = false;

      if (this.currentItem.alertLevelId) {
        this.selectedLevel = this.levels.find(x => x.id === this.currentItem.alertLevelId)
      }
    },
    changeFamily(family) {
      this.loadAlgorithm(family.algorithmFamilyId);
      this.selectedAlgorithm = null;
      this.selectedProperty = null;
    },
    async changeAlgorithm(algorithm) {
      if (this.object) {
        /* let entityType = algorithm.objectRelationships.find(
          (x) => x.objectType === this.currentItem.objectType
        );
        entityType = entityType ? entityType.entityType : "POINT"; */
        let entityType = "ENSEMBLE";
        console.log("changeAlgorithm - loadEntities");
        await this.loadEntities(
          entityType,
          algorithm.algorithmId,
          this.object.elementId || this.object.structureId
        );
      }
      this.properties = this.selectedAlgorithm.outputParams || [];

      if (this.currentItem.algorithmOutputParamId) {
        this.selectedProperty = this.properties.find(x => x.id === this.currentItem.algorithmOutputParamId)
      }
    },
    async changeObjectType(objectType) {
      this.selectedFamily = null;
      this.selectedAlgorithm = null;
      this.selectedProperty = null;
      this.loading = true;
      if (objectType === "STRUCTURE") {
        await this.loadStructure();
        this.families = this.familiesStructures;
      }
      if (objectType === "ELEMENT") {
        await this.loadElements();
        this.families = this.familiesAll;
      }
      this.loading = false;
    },
    async changeObject(object) {
      this.loading = true;
      if (this.selectedAlgorithm) {
        let entityType = this.selectedAlgorithm.objectRelationships.find(
          (x) => x.objectType === this.currentItem.objectType
        );
        entityType = entityType ? entityType.entityType : "POINT";
        console.log("changeObject - loadEntities");
        await this.loadEntities(
          entityType,
          this.selectedAlgorithm.algorithmId,
          object.elementId || object.structureId
        );
      }
      this.loading = false;
    },
    async saveAlert() {
      if (!this.$refs.form.validate()) return;

      this.loading = true;

      try {
        if (this.currentItem.sourceId) {
          // only new item edit not enabled
        } else {
          const payload = {
            objectIds: this.selectedEntities.map(x => x.objectId),
            algorithmId: this.selectedAlgorithm.algorithmId,
            objectId: this.object.structureId || this.object.elementId,
            name: this.currentItem.name,
            description: this.currentItem.description,
            algorithmOutputParamId: this.selectedProperty.id,
            alertOperatorId: this.selectedOperator.operatorId,
            alertLevelId: this.selectedLevel.id,
            value1: this.currentItem.value1,
            value2: this.currentItem.value2,
            limitInclusive: this.limitInclusive,
          }
          this.alert = await this.$store.dispatch("_saveAlert", payload);
        }
      } catch (ex) {
        console.log("error", ex);
      }
      this.$emit("refresh");
      this.$props.onSave();

      this.loading = false;
    },
  },
};
</script>
